










































import { Component, Vue, Prop } from "vue-property-decorator";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { SwiperOptions } from "swiper";

interface Product {
    id: string;
    productFilename?: string;
    productImage?: string;
    productDescription?: string;
    productName?: string;
}

@Component({
    components: {
        Swiper,
        SwiperSlide
    }
})
export default class FeaturedProducts extends Vue {
    @Prop({
        required: true,
        default: []
    })
    products!: Array<Product>;

    activeProductIndex = 0;

    setActiveProduct(activeProductIndex: number) {
        this.activeProductIndex = activeProductIndex;
    }

    get showActiveProduct() {
        return this.products[this.activeProductIndex];
    }

    get getLayoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    swiperOption = {
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 20,
        mousewheel: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        }
    } as SwiperOptions;
}
