<template>
    <div class="bg-white px-8 py-6">
        <Spinners v-if="isLoading" />
        <b-notification
            v-else-if="submitSucess"
            type="is-success"
            has-icon
            aria-close-label="Close notification"
            @close="handleCancel"
        >
            Image updated.
        </b-notification>
        <b-notification
            v-else-if="submitError"
            type="is-danger"
            has-icon
            aria-close-label="Close notification"
            @close="resetSubmitFlags"
        >
            <b>Sorry</b>, there was an error updating the image.
        </b-notification>
        <form v-else @submit.prevent="handleSubmit">
            <h2>Image upload</h2>
            <p>
                <b>Suggested dimensions:</b> <br />
                Portrait: 429px by 762px <br />
                Landscape: 762px by 429px
            </p>

            <div class="mb-4">
                <div class="file has-name">
                    <label class="file-label">
                        <input
                            @change="fileSelect"
                            class="file-input"
                            type="file"
                            accept=".jpeg,.jpg,.png"
                            name="profile-picture"
                        />
                        <span class="file-cta">
                            <span class="file-icon">
                                <font-awesome-icon
                                    class="mr-2"
                                    aria-hidden="true"
                                    icon="upload"
                                />
                            </span>
                            <span class="file-label">
                                Select a file
                            </span>
                        </span>
                        <span class="file-name -ml-1 bg-white">
                            {{ imageFilename ? imageFilename : "..." }}
                        </span>
                    </label>
                </div>
                <p class="text-sm mt-2">Allowed file types: jpg, png</p>
            </div>

            <div class="md:flex items-center justify-between">
                <div class="my-2">
                    <button
                        @click="handleCancel"
                        type="reset"
                        class="block w-full button pill-button button-clear clear-button has-text-weight-bold is-uppercase"
                    >
                        Cancel
                    </button>
                </div>
                <div class="my-2">
                    <button
                        :disabled="!imageFilename"
                        class="block w-full button pill-button is-primary has-text-weight-bold is-uppercase"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { getModule } from "vuex-module-decorators";
import imageVuexModule from "@/store/vuex-modules/image";
import eventHub from "@/event-hub";
import Spinners from "@/components/utilities/Spinners.vue";

const imageStore = getModule(imageVuexModule);

export default {
    name: "ImageUploadForm",
    components: {
        Spinners
    },

    data() {
        return {
            isLoading: false,
            submitSucess: false,
            submitError: false
        };
    },

    computed: {
        imageFilename: {
            get() {
                return imageStore.imageFilename;
            },
            set() {
                // n/a
            }
        }
    },

    created() {
        eventHub.$on("image-file-upload-started", this.handleUpdateStarted);
        eventHub.$on("image-file-upload-success", this.handleUpdateSuccess);
        eventHub.$on("image-file-upload-error", this.handleUpdateError);
        eventHub.$on("image-file-upload-done", this.handleUpdateDone);
    },

    beforeDestroy() {
        this.resetSubmitFlags();

        eventHub.$off("image-file-upload-started", this.handleUpdateStarted);
        eventHub.$off("image-file-upload-success", this.handleUpdateSuccess);
        eventHub.$off("image-file-upload-error", this.handleUpdateError);
        eventHub.$off("image-file-upload-done", this.handleUpdateDone);
    },

    methods: {
        resetFileSelect() {
            imageStore.setImageFile("");
            imageStore.setImageFilename("");
        },
        resetSubmitFlags() {
            this.isLoading = false;
            this.submitSucess = false;
            this.submitError = false;
        },
        handleCancel() {
            this.resetFileSelect();
            this.$emit("cancel");
        },
        handleSubmit() {
            if (!this.imageFilename) return;
            this.$emit("submit");
        },
        handleUpdateStarted() {
            this.isLoading = true;
        },
        handleUpdateSuccess() {
            this.resetFileSelect();
            this.submitSucess = true;
        },
        handleUpdateError() {
            this.submitError = true;
        },
        handleUpdateDone() {
            this.isLoading = false;
        },
        fileSelect(e) {
            const el = e.target;
            const files = el.files;
            let fileName = "";
            let image = "";

            if (files && 0 < files.length) {
                fileName = files[0].name;
                imageStore.setImageFilename(fileName);
            } else {
                this.resetFileSelect();
                return;
            }

            switch (files[0]["type"]) {
                case "image/jpeg":
                case "image/jpg":
                case "image/png":
                    image = files[0];
                    imageStore.setImageFile(image);
                    break;
                default:
                    alert("Sorry that file type is not allowed.");
                    this.resetFileSelect();
                    break;
            }
        }
    }
};
</script>
