


















































































import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";
import ComapanyModule from "@/store/vuex-modules/companies";
import eventHub from "@/event-hub";
import Spinners from "@/components/utilities/Spinners.vue";

const companyStore = getModule(ComapanyModule);

@Component({
    components: { Spinners }
})
export default class ExhibitorLogoUpload extends Vue {
    isLoading = false;
    submitSucess = false;
    submitError = false;

    get avatarImage() {
        return companyStore.logoImage;
    }

    get logoFileName() {
        return companyStore.logoFileName;
    }

    //life cycle
    created() {
        eventHub.$on("company-logo-upload-started", this.handleUpdateStarted);
        eventHub.$on("company-logo-upload-success", this.handleUpdateSuccess);
        eventHub.$on("company-logo-upload-error", this.handleUpdateError);
        eventHub.$on("company-logo-upload-done", this.handleUpdateDone);
    }
    beforeDestroy() {
        this.resetSubmitFlags();

        eventHub.$off("company-logo-upload-started", this.handleUpdateStarted);
        eventHub.$off("company-logo-upload-success", this.handleUpdateSuccess);
        eventHub.$off("company-logo-upload-error", this.handleUpdateError);
        eventHub.$off("company-logo-upload-done", this.handleUpdateDone);
    }

    //methods
    resetFileSelect() {
        companyStore.setCompanyLogoImage("");
        companyStore.setLogoImageFileName("");
    }
    resetSubmitFlags() {
        this.isLoading = false;
        this.submitSucess = false;
        this.submitError = false;
    }
    handleCancel() {
        this.resetFileSelect();
        this.$emit("cancel");
    }
    handleSubmit() {
        if (!this.logoFileName) return;
        this.$emit("submit");
    }
    handleUpdateStarted() {
        this.isLoading = true;
    }
    handleUpdateSuccess() {
        this.resetFileSelect();
        this.submitSucess = true;
    }
    handleUpdateError() {
        this.submitError = true;
    }
    handleUpdateDone() {
        this.isLoading = false;
    }
    fileSelect(e: any) {
        const el = e.target;
        const files = el.files;
        let fileName = "";
        let image = "";

        if (files && 0 < files.length) {
            fileName = files[0].name;
            companyStore.setLogoImageFileName(fileName);
        } else {
            this.resetFileSelect();
            return;
        }

        switch (files[0]["type"]) {
            case "image/jpeg":
            case "image/jpg":
            case "image/png":
                image = files[0];
                companyStore.setCompanyLogoImage(image);
                break;
            default:
                alert("Sorry that file type is not allowed.");
                this.resetFileSelect();
                break;
        }
    }
}
