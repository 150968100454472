














































































































































































































































































































































































































































import { getModule } from "vuex-module-decorators";
import { Vue, Component, Prop } from "vue-property-decorator";
import eventHub from "@/event-hub";

import { TradeshowCompanyObject } from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";
import states from "@/assets/dictionaries/state-list";
import country from "@/assets/dictionaries/country-list";

import comapanyVuexModule from "@/store/vuex-modules/companies";
const companyStore = getModule(comapanyVuexModule);

@Component({
    components: { Spinners }
})
export default class ExhibitorProfileEditForm extends Vue {
    @Prop({ default: "" })
    id!: string;
    beLazy = true;
    value = null;
    isReady = false;

    states = states;
    countries = country;

    selectedState: string[] = [];

    isLoading = false;
    submitSucess = false;
    submitError = false;

    updatePayload: Partial<TradeshowCompanyObject> = {};

    /**
     * Computed
     */
    get stateName() {
        const stateAbbrProperty = "alpha-2";
        return Object(this.states).map(
            (item: Record<string, string>) => item[stateAbbrProperty]
        );
    }

    /**
     * Lifecycle
     */
    created() {
        this.initUpdatePayload();

        eventHub.$on("company-update-started", this.handleUpdateStarted);
        eventHub.$on("company-update-success", this.handleUpdateSuccess);
        eventHub.$on("company-update-error", this.handleUpdateError);
        eventHub.$on("company-update-done", this.handleUpdateDone);
    }

    beforeDestroy() {
        this.resetSubmitFlags();
        this.setUpdatePayload();

        eventHub.$off("company-update-started", this.handleUpdateStarted);
        eventHub.$off("company-update-success", this.handleUpdateSuccess);
        eventHub.$off("company-update-error", this.handleUpdateError);
        eventHub.$off("company-update-done", this.handleUpdateDone);
    }

    /**
     * Methods
     */
    initUpdatePayload() {
        companyStore.initCompanyUpdatePayload();

        this.updatePayload = JSON.parse(
            JSON.stringify(companyStore.companyUpdatePayload)
        );
    }
    resetUpdatePayload() {
        companyStore.resetCompanyUpdatePayload();

        this.updatePayload = JSON.parse(
            JSON.stringify(companyStore.companyResetData)
        );
    }
    resetSubmitFlags() {
        this.isLoading = false;
        this.submitSucess = false;
        this.submitError = false;
    }
    setUpdatePayload() {
        const payload = this.updatePayload;
        companyStore.setCompanyUpdatePayload(payload);
    }
    handleCancel() {
        this.resetUpdatePayload();
        this.$emit("cancel");
    }
    handleSubmit() {
        this.setUpdatePayload();
        this.$emit("submit");
    }
    handleUpdateStarted() {
        this.isLoading = true;
    }
    handleUpdateSuccess() {
        this.submitSucess = true;
    }
    handleUpdateError() {
        this.submitError = true;
    }
    handleUpdateDone() {
        this.isLoading = false;
    }

    addMoreResources() {
        const obj = {
            image: "",
            title: "",
            description: "",
            url: ""
        };
        if (Array.isArray(this.updatePayload.resources)) {
            this.updatePayload.resources.push(obj);
        }
    }

    removeResource(index: number) {
        if (Array.isArray(this.updatePayload.resources)) {
            this.updatePayload.resources.splice(index, 1);
        }
    }
}
