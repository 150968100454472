import Vue from "vue";
import axios from "axios";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";
import {
    TradeshowCompanyObject,
    GetCompanyDataOptions
} from "@/types/interfaces";

// This module is only for functionality related to the following endpoint:
const endpoint = "companies";

@Module({
    dynamic: true,
    store: Store,
    name: "CompanyModule",
    namespaced: true
})
export default class CompanyModule extends VuexModule {
    companyData: TradeshowCompanyObject = {};
    companyUpdatePayload: TradeshowCompanyObject = {};
    companyResetData: Partial<TradeshowCompanyObject> = {};

    logoImage: File | string = "";
    logoFileName = "";
    companyLogoUrl = "";

    heroImage: File | string = "";
    heroFileName = "";
    heroImageUrl = "";

    meetingLogoImage: File | string = "";
    meetingLogoFileName = "";
    meetingLogoUrl = "";

    @Mutation
    public setCompanyLogoImage(data: File | string) {
        this.logoImage = data;
    }
    @Mutation
    public setLogoImageFileName(data: string) {
        this.logoFileName = data;
    }
    @Mutation
    public setCompanyLogoUrl(data: string) {
        this.companyLogoUrl = data;
    }

    @Mutation
    public setCompanyHeroImage(data: File | string) {
        this.heroImage = data;
    }
    @Mutation
    public setHeroImageFileName(data: string) {
        this.heroFileName = data;
    }
    @Mutation
    public setHeroImageUrl(data: string) {
        this.heroImageUrl = data;
    }

    @Mutation
    public setMeetingLogoImage(data: File | string) {
        this.meetingLogoImage = data;
    }
    @Mutation
    public setMeetingLogoFileName(data: string) {
        this.meetingLogoFileName = data;
    }
    @Mutation
    public setMeetingLogoUrl(data: string) {
        this.meetingLogoUrl = data;
    }

    @Mutation
    public setCompany(data: TradeshowCompanyObject) {
        this.companyData = data;
    }

    @Mutation
    public setCompanyUpdatePayload(data: TradeshowCompanyObject) {
        this.companyUpdatePayload = data;
    }

    @Mutation
    public setCompanyResetData(data: Partial<TradeshowCompanyObject>) {
        this.companyResetData = data;
    }

    @Action({ rawError: true })
    public setCompanyResourceImageForUpdate(options: {
        index: number;
        imageGuid: string;
    }) {
        // first
        this.resetCompanyUpdatePayload();

        // then
        const updatePayload = JSON.parse(
            JSON.stringify(this.companyUpdatePayload)
        );
        const resources = updatePayload.resources;

        if (Array.isArray(resources) && resources[options.index]) {
            resources[options.index]["image"] = options.imageGuid;
        }

        this.context.commit("setCompanyUpdatePayload", updatePayload);
    }

    @Action({ rawError: true })
    public initCompanyUpdatePayload() {
        const companyData = JSON.parse(JSON.stringify(this.companyData));

        const {
            brandColor,
            companyDescription,
            featuredDescription,
            companyName,
            featuredTitle,
            featuredVideo,
            address,
            social,
            mainPhone,
            websiteURL,
            resources,
            testimonials
        } = companyData;

        let dataForReset = {
            brandColor,
            companyDescription,
            featuredDescription,
            companyName,
            featuredTitle,
            featuredVideo,
            address,
            social,
            mainPhone,
            websiteURL,
            resources,
            testimonials
        };

        dataForReset = Vue.prototype.MgDefinedProps(dataForReset);

        let dataForUpdate = JSON.parse(
            JSON.stringify(this.companyUpdatePayload)
        );

        if (0 === Object.keys(dataForUpdate).length) {
            dataForUpdate = dataForReset;
        }

        // TODO delete these once this issue is resolved: https://matrix-group.atlassian.net/browse/VMP-1793
        if (false === Boolean("address" in dataForUpdate)) {
            dataForUpdate.address = {};
        }
        // end TODO.

        if (false === Boolean("social" in dataForUpdate)) {
            dataForUpdate.social = {};
        }

        if (false === Boolean("resources" in dataForUpdate)) {
            dataForUpdate.resources = [];
        }

        this.context.commit("setCompanyResetData", dataForReset);
        this.context.commit("setCompanyUpdatePayload", dataForUpdate);
    }

    @Action({ rawError: true })
    public resetCompanyUpdatePayload() {
        this.context.commit("setCompanyUpdatePayload", {});
        this.initCompanyUpdatePayload();
        this.context.commit("setCompanyUpdatePayload", this.companyResetData);
    }

    @Action({ commit: "setCompany", rawError: true })
    getCompany(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${companyId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setCompany", rawError: true })
    putCompany(option: GetCompanyDataOptions) {
        const companyId = option.id;
        const token = this.context.rootGetters.idToken;
        const payload = this.companyUpdatePayload;

        const htmlFields = ["companyDescription", "featuredDescription"];

        htmlFields.forEach((element) => {
            const x = element as keyof TradeshowCompanyObject;
            if (element in payload) {
                payload[x] = Vue.prototype.MgSanitize(payload[x]);
            }
        });

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`/${endpoint}/${companyId}`, payload, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    this.resetCompanyUpdatePayload();
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    //get Company Logo Image URL
    @Action({ commit: "setCompanyLogoUrl", rawError: true })
    async getCompanyLogoUrl(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        try {
            const image = await getApiClient().get(
                `/${endpoint}/${companyId}/companyLogo`,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );

            return image.data;
        } catch (error) {
            throw new Error("Could not get Logo url.");
        }
    }

    //Company Logo Upload
    @Action({ rawError: true })
    async uploadLogoImageFile(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = { data: "" };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/companyLogo-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Company Logo put url!");
        }

        try {
            const putResponse = await axios.request({
                url: putUrlResponse.data,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }

    //get Hero Image URL
    @Action({ commit: "setHeroImageUrl", rawError: true })
    async getHeroImageUrl(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        try {
            const image = await getApiClient().get(
                `/${endpoint}/${companyId}/hero`,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
            return image.data;
        } catch (error) {
            throw new Error("Could not get Logo url.");
        }
    }

    @Action({ rawError: true })
    async uploadHeroImageFile(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = { data: "" };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/hero-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Hero image put url!");
        }

        try {
            const putResponse = await axios.request({
                url: putUrlResponse.data,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }

    @Action({ commit: "setMeetingLogoUrl", rawError: true })
    async getMeetingLogoUrl(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        try {
            const image = await getApiClient().get(
                `/${endpoint}/${companyId}/meeting-logo`,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
            return image.data;
        } catch (error) {
            throw new Error("Could not get meeting Logo url.");
        }
    }

    @Action({ rawError: true })
    async uploadMeetingLogo(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = { data: "" };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/meeting-logo-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get meeting logo image put url!");
        }

        try {
            await axios.request({
                url: putUrlResponse.data,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }
}
