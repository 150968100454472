
















































































































































































































































































































































































































































































































































































































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    TradeshowCompanyObject,
    TradeshowVideoObject,
    CompanyResourcesDataObject,
    ProductData
} from "@/types/interfaces";

import UpcomingStreams from "./UpcomingStreams.vue";
import FeaturedProducts from "./FeaturedProducts.vue";
import CompanyResources from "@/components/exhibitor-profile/sections/CompanyResources.vue";
import SponsorResource from "./FeaturedProducts.vue";
import FeaturedSessions from "./FeaturedSessions.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import AttendeeConnection from "@/components/attendees/AttendeeConnection.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";
import SessionScheduleItem from "@/components/sessions/SessionScheduleItem.vue";

import ExhibitorProfileEditForm from "@/components/exhibitor-profile/ExhibitorProfileEditForm.vue";
import ExhibitorLogoUpload from "@/components/exhibitor-profile/ExhibitorLogoUpload.vue";
import ExhibitorHeroUpload from "@/components/exhibitor-profile/ExhibitorHeroUpload.vue";
import ExhibitorMeetingLogoUpload from "@/components/exhibitor-profile/ExhibitorMeetingLogoUpload.vue";
import GeneralCommentsComponent from "@/components/shared/GeneralCommentComponent.vue";

//social media account
import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgTwitter from "@/components/svg/svg-twitter.vue";

import ComapanyModule from "@/store/vuex-modules/companies";
const companyStore = getModule(ComapanyModule);

import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import axios from "axios";

@Component({
    components: {
        "upcoming-streams": UpcomingStreams,
        "featured-products": FeaturedProducts,
        "featured-sessions": FeaturedSessions,
        "attendee-card": AttendeeCard,
        "svg-facebook": svgFacebook,
        "svg-twitter": svgTwitter,
        "svg-instagram": svgInstagram,
        "svg-linkedin": svgLinkedin,
        ExhibitorLogoCard,
        "sponsor-resource": SponsorResource,
        AttendeeConnection,
        Spinners,
        ExhibitorProfileEditForm,
        ExhibitorLogoUpload,
        ExhibitorHeroUpload,
        CompanyResources,
        GeneralCommentsComponent,
        NotFound,
        SessionScheduleItem,
        ExhibitorMeetingLogoUpload
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class ExhibitorExpandedProfile extends Vue {
    @Prop({
        type: String,
        default: "",
        required: true
    })
    id!: string;
    isLoading = true;
    heroImageLoaded = false;
    // logoFallBackPlaceholder = require("../../assets/images/companyPlaceholder.jpg");
    istemporaryImage = false;
    isModalOpen = false;
    isLogoModalOpen = false;
    isHeroModalOpen = false;
    isMeetingLogoModalOpen = false;
    isLoadingFavorites = true;
    showEditControls = false;

    //life cycle
    created() {
        const companyPromises = [
            this.fetchMeetingLogo(),
            this.fetchCompanyLogo(),
            this.fetchCompany(),
            this.fetchHeroImage(),
            attendeeScheduleStore.getFavoriteSessions()
        ];
        Promise.allSettled(companyPromises).then(() => {
            this.isLoading = false;
            this.isLoadingFavorites = false;

            this.heroImageLoaded = true;

            this.logPageVisit();
        });

        eventHub.$on("image-file-upload-success", this.handleProfileEditSubmit);
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off(
            "image-file-upload-success",
            this.handleProfileEditSubmit
        );
    }

    //  Getters
    get deFaultFallbackHero() {
        let returnvalue = this.$store.getters.getPageOptions("tradeshow")
            .defaultLogo;

        if (returnvalue) {
            returnvalue = `${process.env.BASE_URL}bg/${returnvalue}`;
        } else {
            returnvalue = "";
        }

        return returnvalue;
    }
    get featuredContentHeading() {
        let returnValue = "Upcoming live streams";

        if (this.exhibitorData && this.exhibitorData.featuredTitle) {
            returnValue = this.exhibitorData.featuredTitle;
        } else if (
            this.getConfigurableLabel &&
            this.getConfigurableLabel?.tradeshowExhibitor?.streamHeader
        ) {
            returnValue = this.getConfigurableLabel.tradeshowExhibitor
                .streamHeader;
        }

        return returnValue;
    }

    get layoutImagePagePrimary() {
        let returnvalue = this.$store.getters.getPageOptions("tradeshow")
            .defaultHero;

        if (returnvalue) {
            returnvalue = `${process.env.BASE_URL}bg/${returnvalue}`;
        } else {
            returnvalue = "";
        }

        return returnvalue;
    }

    get companyName() {
        let returnValue = "";

        if (this.exhibitorData?.companyName) {
            returnValue = this.exhibitorData.companyName;
        } else return this.exhibitorData.name;
        return returnValue;
    }
    get avatarUrl() {
        return companyStore.companyLogoUrl;
    }

    get companyApiOptions() {
        const options = { id: this.id };
        return options;
    }
    get companyLogoImage() {
        return companyStore.logoImage;
    }

    get companyHeroImage() {
        return companyStore.heroImage;
    }

    get heroImage() {
        let returnValue = "";

        if (companyStore.heroImageUrl) {
            returnValue = companyStore.heroImageUrl;
        } else if (this.exhibitorData?.images?.hero) {
            returnValue = this.exhibitorData.images.hero;
        } else if (this.layoutImagePagePrimary) {
            returnValue = this.layoutImagePagePrimary;
        }

        return returnValue;
    }

    get companyLogo() {
        let returnValue = "";

        if (this.exhibitorData?.images?.logo) {
            returnValue = this.exhibitorData.images.logo;
        }

        return returnValue;
    }

    get meetingLogoUrl() {
        return companyStore.meetingLogoUrl;
    }

    get meetingLogoImage() {
        return companyStore.meetingLogoImage;
    }

    get usesLocalDataSearch() {
        return this.$store.getters.usesLocalDataSearch;
    }

    get getConfigurableLabel() {
        return this.$store.getters.tradeshowLabel;
    }

    get getLayoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get exhibitorData() {
        return companyStore.companyData;
    }

    get thisIsMyCompany(): boolean {
        const user = this.$store.getters.userInfo;
        return Boolean(user.companyId === this.exhibitorData.companyId);
    }

    get shouldShowEdit() {
        return this.canMakeEdit && this.showEditControls;
    }

    get canMakeEdit() {
        const isSuperUser = this.$store.getters.isSuperUser;
        const isTrue = (currentValue: boolean) => currentValue;
        const user = this.$store.getters.userInfo;
        const hasTier = Boolean(user?.exhibitorTier);

        const conditions = [this.thisIsMyCompany, hasTier];

        return isSuperUser || conditions.every(isTrue);
    }

    get companySessions() {
        return this.exhibitorData?.sessions || [];
    }
    get companyResources() {
        if (this.exhibitorData.resources) {
            return this.exhibitorData?.resources;
        } else {
            return [];
        }
    }

    get companyAttendees() {
        return this.exhibitorData.exhibitor_attendees;
    }

    get featuredProducts() {
        const newObj: TradeshowCompanyObject = companyStore.companyData;
        if (newObj.products) {
            return newObj.products.filter((item: ProductData) => {
                return item.productName != "";
            });
        }
        return [];
    }

    get featuredResources() {
        const newObj: TradeshowCompanyObject = companyStore.companyData;
        if (newObj.resources) {
            return newObj.resources.filter(
                (item: CompanyResourcesDataObject) => {
                    return item.description != "";
                }
            );
        }
        return [];
    }
    get featuredCompanySession() {
        const newObj: TradeshowCompanyObject = companyStore.companyData;
        if (newObj.videos) {
            return newObj.videos.filter((item: TradeshowVideoObject) => {
                return item.url != "";
            });
        }
        return [];
    }

    get pageOptions() {
        return this.$store.getters.getPageOptions("tradeshow");
    }

    get profileOptions() {
        return this.$store.getters.getPageOptions("ExhibitorDetails") || {};
    }

    get sectionHeaderText() {
        return {
            comments: this.pageOptions.content?.commentsHeader
                ? this.pageOptions.content.commentsHeader
                : "Comments",
            attendees: this.pageOptions.content?.attendeesHeader
                ? this.pageOptions.content.attendeesHeader
                : "Organization Participants",
            organizations: this.pageOptions.content?.organizationsHeader
                ? this.pageOptions.content.organizationsHeader
                : "Participating Organizations"
        };
    }

    get cityStateZipCountry() {
        if (!this.exhibitorData || !this.exhibitorData.address) {
            return "";
        }

        const fields = [
            this.exhibitorData.address.city,
            this.exhibitorData.address.state,
            this.exhibitorData.address.zip,
            this.exhibitorData.address.country
        ];

        return fields.filter((field) => field && field != "").join(", ");
    }

    //Method
    fetchCompany() {
        return companyStore.getCompany(this.companyApiOptions);
    }

    fetchCompanyLogo() {
        return companyStore.getCompanyLogoUrl(this.companyApiOptions);
    }
    fetchHeroImage() {
        return companyStore.getHeroImageUrl(this.companyApiOptions);
    }
    fetchMeetingLogo() {
        return companyStore.getMeetingLogoUrl(this.companyApiOptions);
    }
    showModal() {
        this.isModalOpen = true;
    }
    showLogoModal() {
        this.isLogoModalOpen = true;
    }
    showMeetingLogoModal() {
        this.isMeetingLogoModalOpen = true;
    }
    closeMeetingLogoModal() {
        this.isMeetingLogoModalOpen = false;
    }
    showHeroModal() {
        this.isHeroModalOpen = true;
    }

    closeLogoModal() {
        this.isLogoModalOpen = false;
    }
    closeHeroModal() {
        this.isHeroModalOpen = false;
    }
    closeModal() {
        this.isModalOpen = false;
    }

    handleProfileEditSubmit() {
        this.isLoading = true;
        eventHub.$emit("company-update-started");
        companyStore
            .putCompany(this.companyApiOptions)
            .then(() => {
                eventHub.$emit("company-update-success");
            })
            .catch(() => {
                eventHub.$emit("company-update-error");
            })
            .finally(() => {
                eventHub.$emit("company-update-done");
                this.isLoading = false;
            });
    }
    startFileUpload() {
        this.isLoading = true;

        eventHub.$emit("company-logo-upload-started");

        this.handleFileUpload()
            .then(() => {
                eventHub.$emit("company-logo-upload-success");
            })
            .catch(() => {
                eventHub.$emit("company-logo-upload-error");
            })
            .finally(() => {
                eventHub.$emit("company-logo-upload-done");
                this.isLoading = false;
            });
    }
    async handleFileUpload() {
        const options = {
            ...this.companyApiOptions,
            image: this.companyLogoImage
        };

        try {
            await companyStore.uploadLogoImageFile(options);
        } catch (error) {
            throw new Error("Upload failed.");
        }

        try {
            await this.fetchCompanyLogo();
        } catch (error) {
            throw new Error("Get Logo failed.");
        }
    }
    startHeroUpload() {
        this.isLoading = true;

        eventHub.$emit("exhibitor-hero-upload-started");

        this.handleHeroUpload()
            .then(() => {
                eventHub.$emit("exhibitor-hero-upload-success");
            })
            .catch((error) => {
                console.error(error);
                eventHub.$emit("exhibitor-hero-upload-error");
            })
            .finally(() => {
                eventHub.$emit("exhibitor-hero-upload-done");
                this.isLoading = false;
            });
    }
    async handleHeroUpload() {
        const options = {
            ...this.companyApiOptions,
            image: this.companyHeroImage
        };

        try {
            await companyStore.uploadHeroImageFile(options);
        } catch (error) {
            throw new Error(error);
        }

        try {
            await this.fetchHeroImage();
        } catch (error) {
            throw new Error("Get Logo failed.");
        }
    }

    startMeetingLogoUpload() {
        this.isLoading = true;

        eventHub.$emit("company-meeting-logo-upload-started");

        this.handleMeetingLogoUpload()
            .then(() => {
                eventHub.$emit("company-meeting-logo-upload-success");
            })
            .catch(() => {
                eventHub.$emit("company-meeting-logo-upload-error");
            })
            .finally(() => {
                eventHub.$emit("company-meeting-logo-upload-done");
                this.isLoading = false;
            });
    }
    async handleMeetingLogoUpload() {
        const options = {
            ...this.companyApiOptions,
            image: this.meetingLogoImage
        };

        try {
            await companyStore.uploadMeetingLogo(options);
        } catch (error) {
            throw new Error(error);
        }

        try {
            await this.fetchMeetingLogo();
        } catch (error) {
            throw new Error("Get Logo failed.");
        }
    }

    logPageVisit() {
        const uid = this.$store.getters.user.id;
        const username = this.$store.getters.user.username;
        const name = this.$store.getters.user.name;
        const sponsorName = this.exhibitorData?.companyName;
        const sponsorId = this.exhibitorData?.companyId;

        const logData = {
            type: `SponsorView`,
            uid,
            username,
            name,
            sponsorName,
            sponsorId
        };

        logStore.appendLogEntry(logData).catch((err) => console.log(err));
    }

    exhibitorDemoData = {};

    getExhibitorDetails() {
        axios
            .get(
                `https://vmp-back.azurewebsites.net/api/getExhibitor?id=${this.id}`
            )
            .then((response) => {
                this.exhibitorDemoData = response.data;
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error.response.data);
                this.isLoading = false;
            });
    }

    toggleEditControls() {
        this.showEditControls = !this.showEditControls;
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
