












































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { getModule } from "vuex-module-decorators";
import { Component, Vue, Prop } from "vue-property-decorator";

import { CompanyResourcesDataObject } from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";
import ImageUploadForm from "@/components/shared/ImageUploadForm.vue";

import imageVuexModule from "@/store/vuex-modules/image";
const imageStore = getModule(imageVuexModule);

import comapanyVuexModule from "@/store/vuex-modules/companies";
const companyStore = getModule(comapanyVuexModule);

@Component({
    components: {
        Spinners,
        ImageUploadForm
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class CompanyResources extends Vue {
    @Prop({
        required: true,
        default: []
    })
    resources!: Array<CompanyResourcesDataObject>;

    @Prop(Boolean)
    canMakeEdit?: boolean;

    isLoading = true;
    isImageModalOpen = false;
    uploadImageIndex = -1;
    resourceImages: Record<string, string> = {};

    /**
     * Computed
     */
    get companyResources() {
        return this.resources.filter(
            (item: CompanyResourcesDataObject) => item.title
        );
    }

    get imageFile() {
        return imageStore.imageFile;
    }

    /**
     * Lifecycle
     */
    created() {
        this.isLoading = true;

        const imagePromises = this.companyResources.map(
            (item: CompanyResourcesDataObject) => {
                return this.fetchImageFile(item.image);
            }
        );

        Promise.allSettled(imagePromises).then(() => {
            this.isLoading = false;
        });
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    }

    /**
     * Methods
     */
    resetUpdatePayload() {
        companyStore.resetCompanyUpdatePayload();
    }

    showImageModal(event: Event, index: number) {
        event;
        this.uploadImageIndex = index;
        this.isImageModalOpen = true;
    }

    closeImageModal() {
        this.isImageModalOpen = false;
    }

    fetchImageFile(guid: string) {
        return new Promise((resolve, reject) => {
            if (!guid) {
                return reject("no image guid");
            }

            try {
                new URL(guid);
                this.$set(this.resourceImages, guid, guid);
                return resolve("image set");
            } catch (error) {
                // n/a
            }

            imageStore
                .getImage(guid)
                .then((url: string) => {
                    this.$set(this.resourceImages, guid, url);
                    return resolve("image set");
                })
                .catch((error: Error) => {
                    return reject(error);
                });
        });
    }

    startFileUpload() {
        this.isLoading = true;

        eventHub.$emit("image-file-upload-started");

        this.handleFileUpload()
            .then(() => {
                eventHub.$emit("image-file-upload-success");
            })
            .catch(() => {
                eventHub.$emit("image-file-upload-error");
            })
            .finally(() => {
                eventHub.$emit("image-file-upload-done");
                this.isLoading = false;
            });
    }

    async removeImage(index: number) {
        await companyStore.setCompanyResourceImageForUpdate({
            index: index,
            imageGuid: ""
        });

        eventHub.$emit("image-file-upload-success");
    }

    async handleFileUpload() {
        const options = {
            image: this.imageFile
        };
        const uploadImageIndex = this.uploadImageIndex;
        let guid = "";

        try {
            // first
            guid = await imageStore.uploadImage(options);

            // then
            await companyStore.setCompanyResourceImageForUpdate({
                index: uploadImageIndex,
                imageGuid: guid
            });
        } catch (error) {
            throw new Error("Upload failed.");
        }

        try {
            await this.fetchImageFile(guid);
        } catch (error) {
            throw new Error("Get image failed.");
        }
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
