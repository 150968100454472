



























































































import { Component, Vue, Prop } from "vue-property-decorator";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

@Component({
    components: {
        Swiper,
        SwiperSlide
    }
})
export default class AttendeeConnection extends Vue {
    avatarPlaceholder = require("../../assets/avatar2.png");

    attendeeCount(): boolean {
        return this.attendees.length >= 2 ? true : false;
    }

    openDirectMessage(
        firstName: string,
        lastName: string,
        id: string,
        companyName: string
    ): void {
        const fullName = `${firstName} ${lastName}`;

        this.$router.push({
            name: "Message-Center",
            params: {
                id: id,
                name: fullName,
                companyName: companyName
            }
        });
    }

    swiperOption = {
        slidesPerView: 1,
        // slidesPerColumn: 2,
        spaceBetween: 20,
        loop: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 40
            },
            1336: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    };

    get logoColor() {
        const hue =
            "hsl(" +
            360 * Math.random() +
            "," +
            (25 + 70 * Math.random()) +
            "%," +
            (85 + 10 * Math.random()) +
            "%)";
        return { background: `${hue}` };
    }

    get logoInitials() {
        let initials = "";
        const nameArr = this.$props.name.split(" ");
        for (const name of nameArr) {
            initials += name.substring(0, 1);
        }
        return initials;
    }

    @Prop({
        required: true,
        default: []
    })
    attendees!: Array<object>;
}
