var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.attendeeCount)?_c('div',{staticClass:"w-full flex flex-col justify-center md:flex-row"},_vm._l((_vm.attendees),function(attendee){return _c('div',{key:attendee.attendeeId,staticClass:"w-full px-4 mb-8 items-center lg:items-start md:w-1/3 md:mb-0 flex flex-col lg:flex-row justify-center md:px-8 md:py-8"},[_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"block text-white h-16 w-16 rounded-full"},[_c('img',{attrs:{"src":_vm.avatarPlaceholder,"alt":" "}})])]),_c('div',{staticClass:"w-full flex lg:w-3/4 flex-col justify-center lg:items-start"},[_c('div',{staticClass:"text-lg font-semibold"},[_vm._v(" "+_vm._s(attendee.firstName)+" "+_vm._s(attendee.lastName)+" ")]),_c('p',{staticClass:"md:mb-0 text-center lg:text-left  font-semibold"},[_vm._v(" "+_vm._s(attendee.title)+" ")]),_c('p',{staticClass:"text-base text-primary font-semibold"},[_vm._v(" "+_vm._s(attendee.companyName)+" ")]),_c('button',{staticClass:"w-full lg:-ml-16 lg:w-auto bg-tertiary text-sm text-white px-8 py-2 rounded-full uppercase",on:{"click":function($event){return _vm.openDirectMessage(
                            attendee.firstName,
                            attendee.lastName,
                            attendee.attendeeId,
                            attendee.companyName
                        )}}},[_vm._v(" Send message ")])])])}),0):_c('swiper',{staticClass:"px-4 md:px-0 swiper h-64 ml-auto mr-auto",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.attendees),function(attendee){return _c('swiper-slide',{key:attendee.attendeeId,staticClass:"h-56 flex flex-row justify-center"},[_c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"block text-white h-16 w-16 rounded-full bg-accent overflow-hidden"},[_c('img',{attrs:{"src":_vm.avatarPlaceholder,"alt":" "}})])]),_c('div',{staticClass:"flex w-3/4 flex-col items-start"},[_c('a',{staticClass:"text-lg font-semibold",attrs:{"href":""}},[_vm._v(" "+_vm._s(attendee.firstName)+" "+_vm._s(attendee.lastName)+" ")]),_c('p',{staticClass:"mb-0 text-left font-semibold"},[_vm._v(" "+_vm._s(attendee.title)+" ")]),_c('p',{staticClass:"text-base font-semibold"},[_vm._v(" "+_vm._s(attendee.companyName)+" ")]),_c('button',{staticClass:"bg-tertiary text-sm text-white px-8 py-2 rounded-full uppercase",on:{"click":function($event){return _vm.openDirectMessage(
                            attendee.firstName,
                            attendee.lastName,
                            attendee.attendeeId,
                            attendee.companyName
                        )}}},[_vm._v(" Send message ")])])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }