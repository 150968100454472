



























import { Component, Vue, Prop } from "vue-property-decorator";
import { TradeshowVideoObject } from "@/types/interfaces";

@Component
export default class FeaturedSessions extends Vue {
    @Prop({
        required: true,
        default: []
    })
    sessions!: Array<TradeshowVideoObject>;
    // this is a temp filter
    get sessionList() {
        return this.sessions.filter((session: TradeshowVideoObject) => {
            return session.videoName != "";
        });
    }

    navigateToSession(url: string) {
        window.open(url);
    }

    getThumbnail(url: string) {
        if (url) {
            const youtubeId = url.split("/watch?v=").pop();
            return `https://img.youtube.com/vi/${youtubeId}/3.jpg`;
        }
        return "";
    }
}
