














































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";
import { SessionDataObject, AttendeeScheduleSlot } from "@/types/interfaces";
import { formatDistance, subMinutes, differenceInSeconds } from "date-fns";

import scheduleitemVuexModule from "@/store/vuex-modules/scheduleitem";
const scheduleitemStore = getModule(scheduleitemVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import evalsVuexModule from "@/store/vuex-modules/session-evaluations";
const evalsStore = getModule(evalsVuexModule);

@Component({})
export default class SessionScheduleItem extends Vue {
    isLoadingFavoritesLocal = false;
    isSavingFavorite = false;
    favoriteError = false;
    showingBios = false;
    isSessionActive = false;
    isSessionOver = false;
    sessionLabelInterval = 0;
    timeLabel = "";
    hasViewedEnough = false;

    // if the Generic color needs to be change used this state
    // the value will be coming from changeDefaultHeaderColor in the config file
    overideGenericStyle = {
        color: ""
    };

    @Prop({ default: {}, required: true })
    session!: SessionDataObject;

    @Prop(Boolean)
    showDateTime?: boolean;

    @Prop(Boolean)
    isOndemandData?: boolean;

    @Prop({ default: "" })
    accessor!: string;

    @Prop({
        default: true,
        required: true
    })
    isLoadingFavorites?: boolean;

    /**
     * computed
     */
    get user() {
        return this.$store.getters.user;
    }

    get userInfo() {
        return this.$store.getters.userInfo;
    }

    get sessionId() {
        const id = this.session.sessionId;
        return id;
    }

    get isVideoSession() {
        return Boolean(
            !this.session.speakeasyType &&
                !this.session.url &&
                !this.session.noSession
        );
    }

    get pageConfig() {
        return this.$store.getters.getPageOptions("sessions");
    }

    get sessionDateTime() {
        const iso = this.myStartTime;
        return new Date(iso);
    }

    get sessionEarlyAccessMinutes() {
        const session = this.session;
        const returnValue = sessionHelpEarlyAccessMinutes(session);

        return returnValue;
    }

    get myStartTime() {
        const returnValue = this.session.startTime;
        return returnValue;
    }

    get myEndTime() {
        const returnValue = this.session.endTime;
        return returnValue;
    }

    get startTimeAdjusted() {
        const earlyMinutes = this.sessionEarlyAccessMinutes;
        const startTime = this.sessionDateTime;

        return subMinutes(startTime, earlyMinutes);
    }

    get isFavorited() {
        let returnValue = false;

        if (Array.isArray(this.myfavoriteSessions)) {
            const match = this.myfavoriteSessions.find(
                (item: AttendeeScheduleSlot) => {
                    return Boolean(item.id == this.sessionId);
                }
            );

            returnValue = Boolean(match);
        }

        return returnValue;
    }

    get myfavoriteSessions() {
        return attendeeScheduleStore.favoriteSessions;
    }

    get activeSessionEvals() {
        return evalsStore.myResponses.filter(
            (resp) => resp.sessionId === this.session.sessionId
        );
    }

    get evalOptions() {
        return this.$store.getters.getPageOptions("sessionEvals");
    }

    get isEvalEnabled() {
        const isGloballyEnabled =
            this.evalOptions && "enabled" in this.evalOptions
                ? this.evalOptions.enabled
                : true;
        const isSessionEnabled =
            this.session && this.session.disableSessionEval !== true;
        return isGloballyEnabled && isSessionEnabled;
    }

    /**
     * Watchers
     */
    // ...

    /**
     * Life cycle
     */
    created() {
        this.handleActiveSessionCheck();
        this.sessionLabelInterval = window.setInterval(() => {
            this.handleActiveSessionCheck();
        }, 1000);
        this.updateHasViewedEnough();

        this.checkGenericStyle();
    }

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
    }

    /**
     * Methods
     */

    checkGenericStyle() {
        this.overideGenericStyle.color = this.pageConfig.changeDefaultHeaderColor;
    }
    handleFavoriteClick() {
        this.isSavingFavorite = true;
        const payload = {
            type: "sessions",
            id: this.session.sessionId || this.session.id
        };
        let action;

        if (this.isFavorited) {
            action = scheduleitemStore.removeScheduleItem;
        } else {
            action = scheduleitemStore.putSchedule;
        }

        action(payload)
            .then(() => {
                this.isLoadingFavoritesLocal = true;
                attendeeScheduleStore.getFavoriteSessions().finally(() => {
                    this.isLoadingFavoritesLocal = false;
                });
            })
            .catch(() => {
                this.favoriteError = true;
            })
            .finally(() => {
                this.isSavingFavorite = false;
            });
    }

    resetFavoriteFlags() {
        this.favoriteError = false;
    }

    handleActiveSessionCheck() {
        const session = this.session;
        const startDate = this.sessionDateTime;

        this.isSessionActive = sessionHelpSessionIsActive(session);
        this.isSessionOver = sessionHelpSessionIsOver(session);

        this.timeLabel = formatDistance(window.MgServerTime, startDate);
    }

    toggleBiographies() {
        this.showingBios = !this.showingBios;
    }

    goToSession() {
        const url = this.session.url;

        if (!this.isSessionActive) return;

        if (url) {
            let isUrl = false;
            try {
                new URL(url);
                isUrl = true;
            } catch (error) {
                // no need to log error
            }

            if (isUrl) {
                window.open(url, undefined, "noopener,noreferrer");
            } else {
                this.$router.push(url);
            }
        } else if (this.session.speakeasyType) {
            this.$router.push({
                path: `/meeting/SESSION${this.sessionId}`
            });
        } else {
            const startTime = this.startTimeAdjusted;
            const timeSinceStart = differenceInSeconds(
                window.MgServerTime,
                startTime
            );

            this.$router.push({
                name: "Session",
                params: {
                    id: this.sessionId,
                    startVideoAt: `${timeSinceStart}`
                }
            });
        }
    }

    openEvaluation() {
        evalsStore.activateSessionEval({
            sessionId: this.session.sessionId,
            sessionName: this.session.title,
            startTime: this.session.startTime
        });
    }

    async updateHasViewedEnough() {
        if (this.isVideoSession && this.isEvalEnabled) {
            const hasViewedEnough = await evalsStore.hasViewedEnough({
                sessionId: this.session.sessionId,
                startTime: this.session.startTime,
                minutesBeforeSessionEval: this.session.minutesBeforeSessionEval
                    ? this.session.minutesBeforeSessionEval
                    : evalsStore.defaultMinutes
            });

            if (hasViewedEnough) {
                this.hasViewedEnough = true;
            }
        }
    }
}
