var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col items-start  py-4 text-xl font-body",class:_vm.accessor == 'tradeshow' ? 'flex-col' : 'lg:flex-row'},[_c('div',{staticClass:"flex flex-col text-base justify-center w-full pr-4"},[_c('div',{staticClass:"content"},[(_vm.session.title)?_c('h3',{staticClass:"leading-crop-normal",class:_vm.accessor == 'tradeshow' ? 'text-xl mb-0' : '',style:(_vm.overideGenericStyle)},[_vm._v(" "+_vm._s(_vm.session.title)+" ")]):_vm._e(),(_vm.showDateTime)?_c('div',{staticClass:"mb-2 space-x-4 inline-flex"},[_c('div',{class:_vm.accessor == 'tradeshow' ? 'text-sm' : 'my-2'},[_vm._v(" Date: "),_c('b',[_vm._v(_vm._s(_vm.MgFormatISODateTime( _vm.myStartTime, "fullday-month" )))])]),_c('div',{class:_vm.accessor == 'tradeshow' ? 'text-sm' : 'my-2'},[_vm._v(" Time: "),_c('b',[_c('b',[_vm._v(_vm._s(_vm.MgFormatISODateTime(_vm.myStartTime, "time"))+" - "+_vm._s(_vm.MgFormatISODateTime(_vm.myEndTime, "time")))])])])]):_vm._e(),(_vm.session.description)?_c('div',{staticClass:"schedule-description",domProps:{"innerHTML":_vm._s(_vm.session.description)}}):_vm._e(),(
                        _vm.pageConfig.displaySpeakers &&
                            Array.isArray(_vm.session.speakers) &&
                            _vm.session.speakers.length
                    )?_c('div',[_c('h4',[_vm._v(" Speaker(s): ")]),_vm._l((_vm.session.speakers),function(speaker,index){return _c('div',{key:("speaker-" + _vm._uid + "-" + index + "-" + (speaker.id)),staticClass:"mb-4"},[_c('div',{staticClass:"mb-0 text-base text-body-text font-normal font-body"},[(speaker.id)?_c('router-link',{staticClass:"font-bold",attrs:{"to":("/attendees/attendee-profile/" + (speaker.id))}},[_vm._v(" "+_vm._s(speaker.name)+" ")]):_c('span',[_vm._v(_vm._s(speaker.name))]),(speaker.name && speaker.title)?_c('span',{staticClass:"mx-1 text-base text-body-text font-normal"},[_vm._v("–")]):_vm._e(),_c('span',{},[_vm._v(_vm._s(speaker.title))])],1)])})],2):_vm._e()])]),(!_vm.session.noSession)?_c('div',{staticClass:"flex flex-col  justify-start ",class:_vm.accessor == 'tradeshow'
                    ? 'w-10/12 mt-4'
                    : 'w-full md:w-3/6 lg:w-2/6 lg:pr-2'},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"mb-4 flex-1 pr-2"},[(!_vm.session.hasAccess)?_c('button',{key:"NotAuthorized",staticClass:"w-full block button is-primary",attrs:{"disabled":""}},[_vm._v(" Not authorized ")]):_c('button',{key:"Authorized",staticClass:"w-full block button is-primary",attrs:{"disabled":!_vm.isSessionActive},on:{"click":function($event){return _vm.goToSession()}}},[(_vm.isSessionActive)?_c('span',[_vm._v("Join now")]):(_vm.isSessionOver)?_c('span',{staticClass:"text-sm text-body-text"},[_vm._v(" Session has ended ")]):_c('span',{staticClass:"text-sm text-body-text"},[_vm._v(" Session has not yet started ")])]),(_vm.isOndemandData && _vm.isVideoSession)?_c('div',[_c('router-link',{staticClass:"button block is-primary w-full",attrs:{"to":("/sessions/" + _vm.sessionId)}},[_vm._v("Watch on demand")])],1):_vm._e(),(_vm.session.document)?_c('a',{staticClass:"button block w-full mt-4",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.session.document}},[_vm._v("Download Slides")]):_vm._e(),(_vm.hasViewedEnough)?_c('button',{staticClass:"w-full block button mt-4",class:{
                            'pointer-events-none is-success':
                                _vm.activeSessionEvals.length,
                            'is-primary': !_vm.activeSessionEvals.length
                        },on:{"click":function($event){return _vm.openEvaluation()}}},[(_vm.activeSessionEvals.length)?_c('font-awesome-icon',{attrs:{"aria-hidden":"true","icon":"check"}}):_vm._e(),_vm._v(" "+_vm._s(!_vm.activeSessionEvals.length ? "Session Evaluation" : "Evaluation Complete")+" ")],1):_vm._e()]),(
                        _vm.session.hasAccess &&
                            !_vm.isSessionActive &&
                            !_vm.isSessionOver
                    )?_c('div',[(_vm.isSavingFavorite || _vm.isLoadingFavoritesLocal)?_c('div',{key:"saving",staticClass:"text-sm"},[_vm._v(" Saving... ")]):(!_vm.isLoadingFavorites)?_c('b-tooltip',{attrs:{"label":"Add to your personal schedule"}},[_c('button',{staticClass:"text-2xl bg-transparent mr-2",class:{
                                'text-red-500': _vm.isFavorited,
                                'text-fav': !_vm.isFavorited
                            },on:{"click":_vm.handleFavoriteClick}},[_c('font-awesome-icon',{attrs:{"aria-hidden":"true","icon":"heart"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Favorite this session")])],1)]):_vm._e()],1):_vm._e()]),(!_vm.isSessionActive && !_vm.isSessionOver)?_c('div',{staticClass:"w-full flex flex-col justify-center items-start"},[_c('p',{staticClass:"mb-0  font-body",class:_vm.accessor == 'tradeshow' ? 'text-base' : 'text-md'},[_c('span',[_vm._v("Session starts in")]),_c('span',{staticClass:"text-primary font-semibold capitalize block xl:inline",class:_vm.accessor == 'tradeshow'
                                ? 'text-base'
                                : 'text-md'},[_vm._v(" "+_vm._s(_vm.timeLabel)+" ")])])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }