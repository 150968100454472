




















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ExhibitorLogoCard extends Vue {
    @Prop({ default: "Name" })
    name!: string;

    @Prop({ required: false, type: Boolean, default: false }) isLarge!: boolean;

    @Prop({ default: "imgPath" })
    imgPath!: string;

    @Prop({ default: "" })
    id!: string;

    @Prop({ default: "" })
    description!: string;

    @Prop({ default: "" })
    contact!: string;

    @Prop({ default: false })
    hasFullInfo!: string;

    @Prop({ default: false })
    hasActionButtons!: string;

    routeToDetails() {
        if (!this.sectionToUse) return;
        this.$router.push({
            name: "ExhibitorDetails",
            params: { id: this.id }
        });
    }

    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }
    get sectionToUse(): boolean {
        return this.$store.getters.getPageOptions("tradeshow").section
            .companyProfile;
    }
}
